@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

*,h1,h2,h3,h4,h5,h6 {
  font-family: sans-serif;
}

.slick-prev {
  left: 1% !important;
}
.slick-next {
  right: 1% !important;
}

.slick-prev, .slick-next {
  background-color: #fff;
  height: 100px;
  line-height: 100px;
  width: 45px;
  box-shadow: 0 1px 3px #888;
  border-radius: 0 3px 3px 0;
}

.slick-prev {
  z-index: 1;
}

.slick-disabled {
  display: none !important;
  z-index: 0;
}

.slick-dots{
  width: 96% !important;
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  color: #eee;
   /*color: transparent; */
  /* outline: 0; */
  /* background: 0 0; */
}

.slick-next:before, .slick-prev:before {
  font-size: 29px;
  color: #2650a2;
  opacity: 1;
  line-height: 1 !important;
}

.slick-next:hover:before, .slick-prev:hover:before {
  color: #00b04f;
}


.homepage .slick-next, .homepage .slick-prev, .searchpage .slick-next, .searchpage .slick-prev {
  height: 40px;
  line-height: 40px;
  box-shadow: none;  
  background: transparent;
}

.productPageSlider .slick-next, .productPageSlider .slick-prev {
  top: 62%;
  z-index: 1000;
  height: 29px;
  line-height: 40px;
  box-shadow: none;    
  background: transparent;
}

@media screen and (max-width: 600px){
  .mainToolbar {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mainToolbar .MuiButton-label{
      display: flex;
      flex-direction: column;
  }
  .mainToolbar .MuiButton-label p{
      padding-left:0;
  }  
}

.storeMapIcon {
  background-color: rgba(255,255,255, 1);
  border-radius: 4px;
  height: auto !important;
}

.storeMapIcon:after {
  content: '';
  position: absolute;
  bottom: -18px;
  left: 60%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-bottom: 0;
  margin-left: 0px;
  margin-bottom: -15px;
}
